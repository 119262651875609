<template>
  <div class="layout-minimal">
    <div class="layout-minimal__logo">
      <LogoStiftung />
    </div>
    <div class="layout-minimal__body">
      <slot />
    </div>
  </div>
</template>

<script>
import { Folder } from 'beisheim/models'
import LogoStiftung from 'beisheim/components/elements/logo-stiftung'

export default {
  components: {
    LogoStiftung
  },
  asyncData () {
    const resource = Folder.query().first()
    return {
      resource,
      locales: resource.locales
    }
  }
}
</script>

<style lang="scss">
@import '~beisheim/scss/colors';
@import '~beisheim/scss/scale';

.layout-minimal {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__logo {
    width: 250px;
    margin: scale(4);
  }

  &__body {
    min-width: 500px;
    padding: scale(6);
    background-color: gray(4);
  }
}
</style>
