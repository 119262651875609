<template>
  <Layout>
    <Error :error="error" />
  </Layout>
</template>

<script>
import Error from 'beisheim/components/layouts/error'
import Layout from '@/layouts/minimal'

export default {
  components: {
    Layout,
    Error
  },
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
