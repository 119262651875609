<template>
  <BaseLayout :locale="locale">
    <PageHeader
      slot="header"
      logo-image="stiftung"
      :countries="countries"
      :country="country"
      :locale="locale"
      :locales="locales"
      :navigation-actions="resolvedNavigationActions"
      :navigation-items="resolvedMainNavigationItems"
    >
      <div slot="announcement">
        <div v-if="announcementVisibility && news">
          <AnnouncementBar :resource="news" @close="handleAnnouncementClose" />
        </div>
      </div>
    </PageHeader>
    <slot />
    <PageFooter
      slot="footer"
      logo-image="stiftung"
      :items="resolvedFooterNavigationItems"
      :copyright="copyright"
    >
      <template v-if="locale.columns.country === 'ch'">
        Prof.&nbsp;Otto Beisheim&nbsp;Stiftung<br><br>
        Neuhofstrasse&nbsp;4<br>
        CH-6340&nbsp;Baar<br><br>
        Telefon: <a href="tel:+41417687670">+41&nbsp;41&nbsp;768&nbsp;76&nbsp;70</a><br>
        <a href="mailto:info@beisheim-stiftung.com">info@beisheim-stiftung.com</a><br><br>
        <a href="https://ch.linkedin.com/company/beisheim-stiftung-schweiz" target="_blank">
          <LinkedIn class="linkedin-icon" />
          Unser LinkedIn Profil
        </a>
      </template>
      <template v-else>
        Prof.&nbsp;Otto Beisheim&nbsp;Stiftung<br><br>
        Maximilianstraße&nbsp;35&nbsp;C<br>
        D-80539&nbsp;München<br><br>
        Telefon: <a href="tel:+4989215427900">+49&nbsp;89&nbsp;215427-900</a><br>
        <a href="mailto:kontakt@beisheim-stiftung.com">kontakt@beisheim-stiftung.com</a><br><br>
        <a href="https://de.linkedin.com/company/beisheim-stiftung-deutschland" target="_blank">
          <LinkedIn class="linkedin-icon" />
          Unser LinkedIn Profil
        </a>
      </template>
    </PageFooter>
    <OffscreenNavigation
      slot="afterFooter"
      logo-image="stiftung"
      :countries="countries"
      :country="country"
      :locale="locale"
      :locales="locales"
      :items="resolvedMainNavigationItems"
      :actions="resolvedNavigationActions"
    />
  </BaseLayout>
</template>

<script>
import { sortBy, chain } from 'lodash'
import { Navigation, Page, News } from 'beisheim/models'
import BaseLayout from 'beisheim/components/layouts/base'
import PageHeader from 'beisheim/components/elements/page-header'
import PageFooter from 'beisheim/components/elements/page-footer'
import OffscreenNavigation from 'beisheim/components/elements/offscreen-navigation'
import DownloadIcon from 'beisheim/assets/images/icons/beisheim-stiftung-download.svg'
import SearchIcon from 'beisheim/assets/images/icons/beisheim-stiftung-search.svg'
import StiftungstoolIcon from 'beisheim/assets/images/icons/beisheim-stiftung-stiftungstool.svg'
import AnnouncementBar from 'beisheim/components/elements/announcement-bar'
import consola from 'consola'
import LinkedIn from 'beisheim/assets/images/icons/beisheim-linkedin.svg'

const logger = consola.withTag('Layout')

export default {
  components: {
    AnnouncementBar,
    OffscreenNavigation,
    PageFooter,
    PageHeader,
    BaseLayout,
    LinkedIn
  },
  props: {
    countries: {
      type: Array,
      default: () => []
    },
    country: {
      type: Object,
      default: () => ({})
    },
    locale: {
      type: Object,
      default: () => ({})
    },
    locales: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      announcementVisibility: true
    }
  },
  computed: {
    copyright () {
      return `Prof. Otto Beisheim Stiftung © ${new Date().getFullYear()}`
    },
    resolvedMainNavigationItems () {
      const resource = Navigation.query()
        .where('name', 'mainNavigation')
        .where(r => r.country === this.locale.columns.country && r.language === this.locale.columns.language)
        .first()
      if (!resource) {
        logger.error('Whoops no mainNavigation found.')
        return []
      }
      return sortBy(resource.children, 'sort_key')
    },
    resolvedFooterNavigationItems () {
      const resource = Navigation.query()
        .where('name', 'footerNavigation')
        .where(r => r.country === this.locale.columns.country && r.language === this.locale.columns.language)
        .first()

      if (!resource) {
        logger.error('Whoops no footerNavigation found.')
        return []
      }

      return sortBy(resource.children, 'sort_key')
    },
    resolvedNavigationActions () {
      const resource = Navigation.query()
        .where('name', 'actions')
        .where(r => r.country === this.locale.columns.country && r.language === this.locale.columns.language)
        .first()
      if (!resource) { return [] }
      return sortBy(resource.children, 'sort_key').map((resource) => {
        const icons = {
          'downloads': DownloadIcon,
          'search': SearchIcon,
          'request': StiftungstoolIcon
        }
        return {
          resource,
          icon: icons[resource.name]
        }
      })
    },
    news () {
      const cookie = this.$cookies.get('beisheim_announcement')
      const news = News.all()
      let sortedNews = chain(news)
        .orderBy(['columns.date'], ['desc'])
        .filter(r => !!r.columns.announcement && r.country === this.$country && r.language === this.$language)
        .value()

      if (cookie) {
        sortedNews = sortedNews.find(r => r.id !== cookie)
      } else {
        sortedNews = sortedNews[0]
      }

      return sortedNews
    }
  },
  watch: {
    news () {
      if (this.news) {
        this.showAnnouncement()
      } else {
        this.hideAnnouncement()
      }
    }
  },
  methods: {
    resolvePages (locale, pages) {
      const language = locale.columns.language
      const country = locale.columns.country
      const resolvedPages = Page.where((p) => {
        return p.language === language && p.country === country && pages.includes(p.name)
      }).get()
      return resolvedPages
    },
    showAnnouncement () {
      this.announcementVisibility = true
    },
    hideAnnouncement () {
      this.announcementVisibility = false
    },
    handleAnnouncementClose (resource) {
      this.hideAnnouncement()
      this.$cookies.set('beisheim_announcement', resource.id)
    }
  },
  head () {
    return {
      titleTemplate: '%s | Beisheim Stiftung',
      title: 'Document',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Die Beisheim Stiftung mit Sitz in München entwickelt eigene Programme und fördert Projekte in den Bereichen Bildung, Gesundheit, Kultur und Sport.'
        },
        {
          name: 'google-site-verification',
          content: 'IjxJqNGb9hg5UWOLQDOCQBy_Gq4lrLGOOLWxzAhC3yQ'
        }
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: `/favicons/beisheim_stiftung_favicon_32x32.ico` },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `/favicons/beisheim_stiftung_favicon_32x32.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '57x57',
          href: `/favicons/beisheim_stiftung_favicon_57x57.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '72x72',
          href: `/favicons/beisheim_stiftung_favicon_72x72.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '128x128',
          href: `/favicons/beisheim_stiftung_favicon_128x128.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '144x144',
          href: `/favicons/beisheim_stiftung_favicon_144x144.png`
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.linkedin-icon {
  position: relative;
  top: 3px;
}
</style>
